import styled from 'styled-components';

export const Image = styled.img`
  height: 56px;
`;

export const Container = styled.div`
  margin: 0;

  @media (max-width: 768px) {
    margin-top: 5px;
  }
`;
