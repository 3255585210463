import styled, { css } from 'styled-components';
import { makeStyles } from '@mui/styles';
import { COLOR_WHITE, COLOR_GRAY_90, COLOR_GRAY_40, COLOR_PURPLE } from 'styles/variables/colors';

export const useStyles = makeStyles((theme) => ({
  button: {
    padding: theme.spacing(1.5, 3),
    textTransform: 'none',
    fontWeight: theme.typography.fontWeightBold,
    borderRadius: 40,
  },
  whiteButton: {
    marginRight: theme.spacing(1),
    color: theme.palette.grey[900],
    borderColor: theme.palette.grey[900],
  },
  fixed: {
    [theme.breakpoints.up('sm')]: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      zIndex: 999,
      backgroundColor: theme.palette.common.white,
      boxShadow: '0px 2px 1px rgba(0, 0, 0, 0.2)',
      transition: 'background 0.7s',
      display: 'flex',
      justifyContent: 'space-between',
    },
  },
  menuItemBlack: {
    color: '#000',
  },
  menuItemPurple: {
    color: COLOR_PURPLE,
  },
  btnLoginScrolled: {
    color: theme.palette.grey[900],
    borderColor: theme.palette.grey[900],
  },
  buttonLoginSecondary: {
    marginRight: theme.spacing(1),
    color: theme.palette.common.white,
    borderColor: theme.palette.common.white,
  },
}));

export const Container = styled.header`
  width: 100%;
  height: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;

  @media (min-width: 768px) {
    height: 72;
    padding: 0 20px;
  }

  @media (max-width: 768px) {
    margin-top: 0;
  }
`;

export const ButtonGroup = styled.div`
  display: flex;
  align-items: stretch;
  height: 40px;

  & button {
    margin-bottom: 0;
  }

  & > button:first-child {
    margin-right: 12px;
  }

  @media (max-width: 768px) {
    padding: 20px;
    height: 89px;
    width: 100%;

    & > button:last-child {
      width: 60%;
    }

    & > button:first-child {
      border-color: ${COLOR_GRAY_40} !important;
      color: ${COLOR_GRAY_90};
    }
  }
`;

export const Menu = styled.div`
  @media (max-width: 768px) {
    display: none;
    flex-direction: column;
    width: 100%;
  }
`;

export const MenuItem = styled.a`
  background: transparent;
  border: none;
  color: ${COLOR_WHITE};
  font-weight: bold;
  margin-right: 30px;
  cursor: pointer;
  padding: 0;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }

  @media (max-width: 768px) {
    color: ${COLOR_GRAY_90};
    width: 100%;
    width: 100%;
    margin: 0;
    padding: 15px 20px;
    font-size: 1.125rem;
  }
`;

export const Nav = styled.nav`
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    z-index: 20;
    width: 100%;
    background: white;
    position: fixed;
    right: 0;
    top: 0;
    height: 100vh;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    transition: 0.3s ease;
    transform: translateY(-100%);

    ${(props) =>
      props.isOpen &&
      css`
        transform: translateY(0);
      `}
  }
`;

export const AvatarButton = styled.button`
  background: transparent;
  border: 0;
  outline: 0;
  padding: 0;
`;

export const AvatarImage = styled.img`
  height: 40px !important;
  width: 40px !important;
  border-radius: 50%;
`;
