import clsx from 'clsx';
import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import { useStyles } from './styles';
import { useModal } from 'contexts/EmailFormModalContext';

const Props = {
  variant: PropTypes.oneOf([
    'text',
    'outlined',
    'outlined-new',
    'buttonWhite',
    'buttonSecondary',
    'buttonWhiteTextPurple',
    'transparentButtonBorderWhite',
    'ctaPinkOpenModal',
    'ctaPink',
    'ctaPrimaryOpenModal',
    'ctaPrimaryOutlineOpenModal',
    'ctaPrimaryOutline',
    'buttonSecondaryOpenModal',
    'ctaPinkOutline',
  ]),
  color: PropTypes.oneOf(['primary']),
};

function CustomButton({ children, variant, color, ...rest }) {
  const classes = useStyles();
  const { setIsModalOpen } = useModal();

  if (variant === 'contained') {
    return;
  }

  if (variant === 'text') {
    return (
      <Button
        {...rest}
        className={clsx(classes.root, {
          [classes.textPrimary]: color === 'primary',
        })}
      >
        {children}
      </Button>
    );
  }

  if (variant === 'outlined') {
    return (
      <Button {...rest} variant="outlined" className={classes.root}>
        {children}
      </Button>
    );
  }

  if (variant === 'outlined-new') {
    return (
      <Button {...rest} variant="outlined" className={classes.outlinedNew}>
        {children}
      </Button>
    );
  }

  if (variant === 'buttonWhite') {
    return (
      <Button {...rest} className={clsx(classes.root, classes.buttonWhite)}>
        {children}
      </Button>
    );
  }

  if (variant === 'buttonSecondary') {
    return (
      <Button {...rest} className={clsx(classes.root, classes.buttonSecondary)}>
        {children}
      </Button>
    );
  }

  if (variant === 'buttonWhiteTextPurple') {
    return (
      <Button {...rest} className={clsx(classes.root, classes.buttonWhiteTextPurple)}>
        {children}
      </Button>
    );
  }

  if (variant === 'transparentButtonBorderWhite') {
    return (
      <Button {...rest} className={clsx(classes.root, classes.transparentButtonBorderWhite)}>
        {children}
      </Button>
    );
  }
  if (variant === 'ctaPink') {
    return (
      <Button {...rest} className={clsx(classes.root, classes.ctaPink)}>
        {children}
      </Button>
    );
  }

  if (variant === 'ctaPinkOpenModal') {
    return (
      <Button {...rest} onClick={setIsModalOpen} className={clsx(classes.root, classes.ctaPink)}>
        {children}
      </Button>
    );
  }

  if (variant === 'ctaPrimaryOpenModal') {
    return (
      <Button {...rest} className={clsx(classes.root, classes.ctaPrimary)} onClick={setIsModalOpen}>
        {children}
      </Button>
    );
  }

  if (variant === 'ctaPrimaryOutlineOpenModal') {
    return (
      <Button
        {...rest}
        className={clsx(classes.root, classes.ctaPrimaryOutline)}
        onClick={setIsModalOpen}
      >
        {children}
      </Button>
    );
  }

  if (variant === 'ctaPrimaryOutline') {
    return (
      <Button {...rest} className={clsx(classes.root, classes.ctaPrimaryOutline)}>
        {children}
      </Button>
    );
  }

  if (variant === 'ctaPinkOutline') {
    return (
      <Button {...rest} className={clsx(classes.root, classes.ctaPinkOutline)}>
        {children}
      </Button>
    );
  }

  if (variant === 'buttonSecondaryOpenModal') {
    return (
      <Button
        {...rest}
        className={clsx(classes.root, classes.buttonSecondaryOpenModal)}
        onClick={setIsModalOpen}
      >
        {children}
      </Button>
    );
  }
  return (
    <Button {...rest} className={clsx(classes.root, classes.containedPrimary)} variant="contained">
      {children}
    </Button>
  );
}

CustomButton.propTypes = Props;

const CButton = forwardRef((props, ref) => <CustomButton {...props} />);
CButton.displayName = 'CustomButton';

export default CButton;
