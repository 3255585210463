import styled, { css } from 'styled-components';

import margin from 'styles/utils/margin';

export const Placeholder = styled.img``;

export const IconImg = styled.img``;

export const IconContainer = styled.div`
  ${margin}

  background: rgba(240, 240 ,240, 1);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  ${(props) =>
    props.size === 'md' &&
    css`
      height: 86px;
      width: 86px;

      & ${IconImg} {
        height: 50px;
      }
    `}

  ${(props) =>
    props.size === 'lg' &&
    css`
      height: 124px;
      width: 124px;

      & ${IconImg} {
        height: 70px;
      }
    `}

  ${(props) =>
    props.size === 'sm' &&
    css`
      height: 40px;
      width: 40px;

      & ${IconImg} {
        height: 20px;
      }
    `}
`;

export const UserImg = styled.img`
  ${margin}

  border-radius: 50%;

  ${(props) =>
    props.size === 'md' &&
    css`
      height: 86px;
      width: 86px;
    `}

  ${(props) =>
    props.size === 'lg' &&
    css`
      height: 124px;
      width: 124px;
    `}

  ${(props) =>
    props.size === 'sm' &&
    css`
      height: 40px;
      width: 40px;
    `}
`;
