export default {
  lang: 'ko',
  allSolutions: '바로가기 링크',
  labsAndInstitutions: '연구실 및 기관',
  rewards: '리워드 프로그램 안내',
  aboutUs: '회사 소개',
  support: '고객센터',

  editageName: 'Editage',
  editageDescription: '전문가가 작업하는 영문교정, 번역, 저널투고지원 서비스',
  editageLink: 'https://www.editage.co.kr?source=mtg_kor_globalnav',

  paperpalName: 'Paperpal',
  paperpalDescription: '언어개선, 패러프레이징 가능 학술 글쓰기 도구',
  paperpalLink: 'https://paperpal.com/ko?source=mtg_kor_globalnav',

  rDiscoveryName: 'R Discovery',
  rDiscoveryDescription: '관련분야 최신 출판논문 제공 문헌검색 도구',
  rDiscoveryLink: 'https://discovery.researcher.life?source=mtg_kor_globalnav',

  mindTheGraphName: 'Mind the Graph',
  mindTheGraphDescription: ' 도표, 그래픽초록, 인포그래픽 등 과학 연구이미지 생성',
  mindTheGraphLink: '/',

  unlockUnlimitedText: (
    <>
      모든 AI도구의 혜택를 제한없이 활용할 수 <br />
      있는 <b>All Access Pack</b> 멤버십
    </>
  ),
  unlockUnlimitedButtonText: 'All Access Pack 상세 보기',
  unlimitedLink: 'https://www.editage.co.kr/all-access-pack?source=mtg_kor_globalnav',
};
