export default {
  lang: 'jp',
  allSolutions: 'すべてのサービスとツール',
  labsAndInstitutions: '研究室、団体のお客様',
  rewards: 'ポイントプログラム',
  aboutUs: 'エディテージについて',
  support: 'ヘルプ',

  editageName: 'Editage',
  editageDescription: '研究者のあらゆるニーズに1つのプラットフォームで対応',
  editageLink: 'https://www.editage.jp?source=mtg_jpn_globalnav',

  paperpalName: 'Paperpal',
  paperpalDescription: 'AIを活用したアカデミック・ライティン グ・アシスタント',
  paperpalLink: 'https://paperpal.com/ja?source=mtg_jpn_globalnav',

  rDiscoveryName: 'R Discovery',
  rDiscoveryDescription: 'AI文献検索の最良のパートナー',
  rDiscoveryLink: 'https://discovery.researcher.life?source=mtg_jpn_globalnav',

  mindTheGraphName: 'Mind the Graph',
  mindTheGraphDescription: 'グラフィック、イラスト、アートワーク用AIツール',
  mindTheGraphLink: '/',

  unlockUnlimitedText: (
    <>
      各種AIツールに無制限でアクセスできる、
      <br />
      エディテージ<b>Editage All Access Pack</b>で、
      <br />
      研究の効率と効果を上げる。
    </>
  ),
  unlockUnlimitedButtonText: 'エディテージ・オールアクセス',
  unlimitedLink: 'https://www.editage.jp/all-access-pack?source=mtg_jpn_globalnav',
};
