import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';
import { v4 as uuid } from 'uuid';
import { Menu as Dropdown, MenuItem as DropdownItem, Button, Box } from '@mui/material';
import BrandImage from 'components/BrandImage';
import { useStyles, Container, ButtonGroup, Nav, MenuItem, Menu, AvatarButton } from './styles';
import { UserTypeEnum } from 'enums';
import { useRouter } from 'next/router';
import ProfileAvatar from 'components/ProfileAvatar';
import CustomButton from 'components/CustomButton';
import { checkUserCountry, setLocale } from 'utils/locale';

import en from './locales/en';
import ja from './locales/en';
import zh from './locales/en';
import es from './locales/es';
import fr from './locales/fr';
import it from './locales/it';
import { Close, Menu as MenuIcon } from '@mui/icons-material';
import Image from 'next/image';
import { flowTrialActive, getMtgContinueUrl } from 'utils';
import useIsMobile from 'hooks/useIsMobile';
import getCleverTap from 'utils/clevertap';
import { useAuthUser } from 'features/auth/AuthUserContext';
import { EditageConfig } from 'config/onelogin.config';
import useGeoData from 'hooks/useGeoData';

const Props = {
  brandSrc: PropTypes.string.isRequired,
  brandAlt: PropTypes.string.isRequired,
  brandType: PropTypes.oneOf(['default', 'editage']).isRequired,
  brandHref: PropTypes.string.isRequired,
  bgBrandColor: PropTypes.string,
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string,
      label: PropTypes.string,
      isExternal: PropTypes.bool,
    }),
  ),
  isNavOpen: PropTypes.bool.isRequired,
  onNavClose: PropTypes.func.isRequired,
  onNavOpen: PropTypes.func.isRequired,
  hamburgerMenuActive: PropTypes.bool,
  headerWhite: PropTypes.bool,
  scrolled: PropTypes.bool,
  textSignIn: PropTypes.string,
  textSignUp: PropTypes.string,
};

const DefaultProps = {
  scrolled: false,
  hamburgerMenuActive: true,
  headerWhite: false,
};

export default function PageHeader({
  brandSrc,
  brandAlt,
  brandType,
  brandHref,
  menuItems,
  bgBrandColor,
  isNavOpen,
  onNavClose,
  onNavOpen,
  typeBtn,
  scrolled,
  hamburgerMenuActive,
  headerWhite,
  textSignIn,
  textSignUp,
}) {
  const classes = useStyles();
  const router = useRouter();
  const { login, isAuthenticated, isLoading, user } = useAuthUser();
  const isMobile = useIsMobile();
  const [dropdownAnchorEl, setDropdownAnchorEl] = useState();
  const { locale } = router;
  const isIllustrationsPage = router.asPath.includes('/illustrations');
  const t = isIllustrationsPage
    ? setLocale(locale, { en, es, fr, it, ja, zh })
    : setLocale('en', { en });
  const [userIsChina, setUserIsChina] = useState(false);
  const [userIsJapan, setUserIsJapan] = useState(false);
  const clevertap = getCleverTap();
  const { geoData } = useGeoData();

  async function getCountryFromUser() {
    const isChina = await checkUserCountry.isChina(user);
    const isJapan = await checkUserCountry.isJapan(user);

    setUserIsChina(isChina);
    setUserIsJapan(isJapan);
  }

  function handleClevertapEvent() {
    clevertap.event.push('Clicks to view information detail', {
      Product: 'MTG',
      Email: user.email,
      'Country code': user.country_code,
      URL: router.asPath,
      Description: 'Other Clicks',
    });
  }

  useEffect(() => {
    getCountryFromUser();
  }, [user]);

  function getOneLoginLink(redirectUrl = '/my-creations') {
    const countryCode = geoData?.country_code || 'US';
    const domain =
      countryCode === 'CN' ? EditageConfig.sso.chineseDomain : EditageConfig.sso.mainDomain;
    return `${domain}/login?continue=${encodeURIComponent(getMtgContinueUrl(redirectUrl))}`;
  }

  function handleButtonGroup() {
    if (flowTrialActive && !(userIsChina || userIsJapan)) {
      return (
        <ButtonGroup>
          <Link
            prefetch={false}
            href={`/auth/login-register?from_url=${
              router.basePath + router.pathname
            }&onlyLogin=true`}
            passHref
          >
            <CustomButton variant="buttonSecondary">{t.getStarted}</CustomButton>
          </Link>
        </ButtonGroup>
      );
    }

    return (
      <ButtonGroup>
        <Link href={getOneLoginLink()} passHref prefetch={false}>
          <Button
            component="a"
            className={clsx(classes.button, classes.whiteButton, {
              [classes.btnLoginScrolled]: scrolled,
              [classes.buttonLoginSecondary]: !headerWhite,
            })}
            variant="outlined"
          >
            {textSignIn || t.signIn}
          </Button>
        </Link>

        {typeBtn === 'buttonSecondaryOpenModal' && (
          <CustomButton variant={typeBtn || 'contained'}>{textSignUp || t.signUp}</CustomButton>
        )}

        {typeBtn !== 'buttonSecondaryOpenModal' && (
          <Link
            prefetch={false}
            href={`/auth/login-register?from_url=${
              router.basePath + router.pathname
            }&onlyLogin=true`}
            passHref
          >
            <CustomButton variant={typeBtn || 'contained'}>{textSignUp || t.signUp}</CustomButton>
          </Link>
        )}
      </ButtonGroup>
    );
  }

  return (
    <Container className={clsx({ [classes.fixed]: scrolled })}>
      {!isMobile && <BrandImage href={brandHref} src={brandSrc} alt={brandAlt} />}

      {hamburgerMenuActive && (
        <Box display={{ xs: 'block', sm: 'none' }} width="100%">
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <BrandImage
              href={brandHref}
              src={
                brandType === 'editage'
                  ? '/images/mtg-logo-red-editage.svg'
                  : '/images/mtg-logo-red.svg'
              }
              alt={brandAlt}
            />

            <MenuIcon
              fontSize="large"
              onClick={onNavOpen}
              aria-label="menu"
              style={{ color: bgBrandColor == '#ffffff' ? 'black' : 'white' }}
            />
          </Box>
        </Box>
      )}

      <Nav isOpen={isNavOpen}>
        <Box position="absolute" top={0} right={0} m={2} display={{ xs: 'block', sm: 'none' }}>
          <Close onClick={onNavClose} fontSize="large" />
        </Box>

        <Box ml={2} display={{ xs: 'block', sm: 'none' }}>
          <BrandImage
            href={brandHref}
            src={
              brandType === 'editage'
                ? '/images/mtg-logo-red-editage.svg'
                : '/images/mtg-logo-red.svg'
            }
            alt={brandAlt}
          />
        </Box>

        <Menu>
          {menuItems.map((menuItem) =>
            menuItem.isLogged ? (
              isAuthenticated &&
              (menuItem.isExternal ? (
                <MenuItem
                  className={clsx({ [classes.menuItemBlack]: scrolled || headerWhite })}
                  key={uuid()}
                  href={menuItem.path}
                  target={menuItem.isBlank ? '_blank' : '_self'}
                >
                  {menuItem.label}
                </MenuItem>
              ) : (
                <Link prefetch={false} key={uuid()} href={menuItem.path} passHref>
                  <MenuItem
                    className={clsx({
                      [classes.menuItemBlack]:
                        (scrolled || headerWhite) && router.pathname !== menuItem.path,
                      [classes.menuItemPurple]:
                        (scrolled || headerWhite) && router.pathname === menuItem.path,
                    })}
                  >
                    {menuItem.label}
                  </MenuItem>
                </Link>
              ))
            ) : menuItem.isExternal ? (
              <MenuItem
                className={clsx({ [classes.menuItemBlack]: scrolled || headerWhite })}
                key={uuid()}
                href={menuItem.path}
                target={menuItem.isBlank ? '_blank' : '_self'}
              >
                {menuItem.label}
              </MenuItem>
            ) : (
              <Link prefetch={false} key={uuid()} href={menuItem.path} passHref>
                <MenuItem
                  className={clsx({
                    [classes.menuItemBlack]:
                      (scrolled || headerWhite) && router.pathname !== menuItem.path,
                    [classes.menuItemPurple]:
                      (scrolled || headerWhite) && router.pathname === menuItem.path,
                  })}
                >
                  {menuItem.label}
                </MenuItem>
              </Link>
            ),
          )}
        </Menu>

        <Box display={{ xs: 'none', sm: 'block' }}>
          {!isLoading &&
            (isAuthenticated ? (
              <>
                <Box ml={2}>
                  <AvatarButton onClick={(event) => setDropdownAnchorEl(event.currentTarget)}>
                    <ProfileAvatar size="sm" />
                  </AvatarButton>
                </Box>
                <Dropdown
                  anchorEl={dropdownAnchorEl}
                  open={Boolean(dropdownAnchorEl)}
                  onClose={() => setDropdownAnchorEl(null)}
                >
                  {user.tp_user === UserTypeEnum.ADMIN && [
                    <DropdownItem key="admin-link" component="a" href={'/admin'}>
                      New Admin
                    </DropdownItem>,
                    <DropdownItem key="dashboard-link" component="a" href={'/admin/dashboard'}>
                      New Dashboard
                    </DropdownItem>,
                  ]}

                  <Link prefetch={false} href="/my-account/overview" passHref>
                    <DropdownItem onClick={handleClevertapEvent} component="a">
                      Account
                    </DropdownItem>
                  </Link>

                  <Link prefetch={false} href="/my-creations" passHref>
                    <DropdownItem component="a">My Creations</DropdownItem>
                  </Link>

                  <Link prefetch={false} href="/auth/logout" passHref>
                    <DropdownItem component="a" style={{ width: '100%' }}>
                      Logout
                    </DropdownItem>
                  </Link>
                </Dropdown>
              </>
            ) : (
              handleButtonGroup()
            ))}
        </Box>

        <Box
          mt={2}
          mb={2}
          borderTop={1}
          borderColor="grey.300"
          pt={2}
          width={1}
          display={{ xs: 'block', sm: 'none' }}
        >
          <Box ml={2} mr={2}>
            <Link href="https://www.editage.com">
              <div>
                <Image src="/images/editage-clean-logo.svg" alt="Editage" width={108} height={28} />
              </div>
            </Link>
          </Box>
        </Box>
      </Nav>
    </Container>
  );
}

PageHeader.propTypes = Props;
PageHeader.defaultProps = DefaultProps;
