import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  mainNavBar: {
    backgroundColor: '#EFF2FB',
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
    paddingRight: theme.spacing(2.5),
    height: theme.spacing(5.5),
    gridGap: theme.spacing(2),
  },
  linkButton: {
    fontSize: 13,
    fontWeight: 600,
    lineHeight: '20px',
    textAlign: 'center',
    textDecoration: 'none',
    color: '#1F2937',

    '&:hover': {
      color: '#113DCD',
      textDecoration: 'none',
    },
  },
  arrowIcon: {
    color: '#1F2937',
    fontSize: 18,
    marginBottom: theme.spacing(-0.5),
  },
  nameProduct: {
    fontSize: 14,
    fontWeight: 600,
    lineHeight: '20px',
    color: '#1F2937',
    margin: 0,
  },
  descriptionProduct: {
    color: '#4B5563',
    fontSize: 12,
    fontWeight: 400,
    lineHeight: '20px',
    margin: 0,
  },
  menuItem: {
    margin: theme.spacing(0, 2),

    '&:hover': {
      background: '#EFF2FB',
      margin: theme.spacing(0, 2),
      borderRadius: 4,
    },

    '& a': {
      textDecoration: 'none',
    },
  },
  firstMenuItem: {
    position: 'relative',

    '&::after': {
      display: 'block',
      position: 'absolute',
      top: 165,
      left: 0,
      content: '""',
      width: 4,
      height: 37,
      borderRadius: '0px 3px 3px 0px',
      backgroundColor: '#113DCD',
    },
  },
  lastMenuItemList: {
    padding: theme.spacing(2, 4),
    backgroundColor: '#EFF2FB',
    marginBottom: theme.spacing(-1),
    marginTop: theme.spacing(1),

    '&:hover': {
      backgroundColor: '#EFF2FB',
    },

    '& p': {
      fontSize: 14,
      fontWeight: 500,
      lineHeight: '22px',
      color: '#4B5563',
    },

    '& span': {
      fontWeight: 700,
    },
  },
  exploreEditageButton: {
    textDecoration: 'none',
    backgroundColor: '#113DCD',
    borderRadius: 50,
    padding: theme.spacing(0.8, 1),
    fontSize: 14,
    fontWeight: 700,
    lineHeight: '24px',
    color: theme.palette.common.white,
    textTransform: 'none',

    '&:hover': {
      textDecoration: 'none',
      backgroundColor: '#113DCD',
      color: theme.palette.common.white,
    },
  },
  supportOptions: {
    fontSize: 12,
    fontWeight: 600,
    lineHeight: '20px',
    color: '#1F2937',
    margin: 0,
  },
  linkActive: {
    color: '#113DCD',
  },
}));
