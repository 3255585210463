import React from 'react';
import PropTypes from 'prop-types';
import { Image, Container } from './styles';
import Link from 'next/link';

const Props = {
  onClick: PropTypes.func,
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
};

const DefaultProps = {
  onClick: () => {},
};

/**
 * Brand logo image
 * @param {object} props
 * @param {string} props.src - the image path
 * @param {string} props.alt - the image description
 */
export default function BrandImage({ href, src, alt }) {
  return (
    <Link passHref href={href}>
      <a>
        <Container>
          <Image src={src} alt={alt} width={135} height={32} />
        </Container>
      </a>
    </Link>
  );
}

BrandImage.propTypes = Props;
BrandImage.defaultProps = DefaultProps;
