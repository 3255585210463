import clsx from 'clsx';
import { MenuItem, Box, Link, Typography, Button } from '@mui/material';
import { Icons } from 'components/Icons';
import { useRouter } from 'next/router';
import { setLocale } from 'utils/locale';
import { useStyles } from './styles';
import PropTypes from 'prop-types';
import HoverMenu from 'material-ui-popup-state/HoverMenu';
import { usePopupState, bindHover, bindMenu } from 'material-ui-popup-state/hooks';

import en from './locales/en';
import ja from './locales/jp';
import ko from './locales/ko';
import es from './locales/es';
import zh from './locales/en';

const Props = {
  localeProp: PropTypes.string,
};

const DefaultProps = {
  localeProp: '',
};

const HIDDEN_PATH_PREFIXES = ['/anniversary'];

export default function NavBarOneResearcher({ localeProp }) {
  const router = useRouter();
  const classes = useStyles();
  const { locale, pathname } = router;
  const isIllustrationsPage = router.asPath.includes('/illustrations');
  const supportedLocales = { en, es, ja, ko, zh };
  const currentLocale = localeProp && supportedLocales[localeProp] ? localeProp : locale;
  const t = isIllustrationsPage
    ? setLocale(locale, supportedLocales)
    : setLocale(currentLocale, supportedLocales);

  const popupStateSolutions = usePopupState({
    variant: 'popover',
    popupId: 'solutions',
  });
  const popupStateSupport = usePopupState({
    variant: 'popover',
    popupId: 'support',
  });
  const isHiddenPath = HIDDEN_PATH_PREFIXES.some(
    (prefix) => pathname === prefix || pathname.startsWith(`${prefix}/`),
  );

  if (t === undefined) {
    throw new Error('Locale not found');
  }

  const items = [
    {
      id: 1,
      name: t.editageName,
      description: t.editageDescription,
      link: t.editageLink,
    },
    {
      id: 2,
      name: t.paperpalName,
      description: t.paperpalDescription,
      link: t.paperpalLink,
    },
    {
      id: 3,
      name: t.rDiscoveryName,
      description: t.rDiscoveryDescription,
      link: t.rDiscoveryLink,
    },
    {
      id: 4,
      name: t.mindTheGraphName,
      description: t.mindTheGraphDescription,
      link: t.mindTheGraphLink,
    },
  ];

  function dropDownSolutions() {
    return (
      <HoverMenu
        {...bindMenu(popupStateSolutions)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <div className={classes.firstMenuItem} />

        {items.map((item) => (
          <MenuItem key={item.id} className={classes.menuItem}>
            <Link
              target="_blank"
              href={item.link}
              passHref
              style={{ textDecoration: 'none' }}
              underline="hover"
            >
              <Box display="flex" flexDirection="column">
                <p className={classes.nameProduct}>{item.name}</p>

                <p
                  className={classes.descriptionProduct}
                  style={{ fontWeight: t.lang === 'en' ? 600 : 400 }}
                >
                  {item.description}
                </p>
              </Box>
            </Link>
          </MenuItem>
        ))}

        <MenuItem disableRipple className={classes.lastMenuItemList}>
          <Box display="flex" flexDirection="column" gap="18px">
            <Typography>{t.unlockUnlimitedText}</Typography>

            <Button
              target="_blank"
              href={t.unlimitedLink}
              className={classes.exploreEditageButton}
              disableRipple
            >
              {t.unlockUnlimitedButtonText}
            </Button>
          </Box>
        </MenuItem>
      </HoverMenu>
    );
  }

  function dropDownSupport() {
    return (
      <HoverMenu
        {...bindMenu(popupStateSupport)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem className={classes.menuItem}>
          <p className={classes.supportOptions}>+1 650-265-1813</p>
        </MenuItem>

        <MenuItem className={classes.menuItem}>
          <a href="mailto:contact@mindthegraph.com">
            <p className={classes.supportOptions}>contact@mindthegraph.com</p>
          </a>
        </MenuItem>
      </HoverMenu>
    );
  }

  if (isHiddenPath) {
    return null;
  }

  return (
    <Box className={classes.mainNavBar}>
      {dropDownSolutions()}

      <Link
        className={clsx(
          classes.linkButton,
          popupStateSolutions.isOpen === true && classes.linkActive,
        )}
        {...bindHover(popupStateSolutions)}
        underline="hover"
      >
        {t.allSolutions}{' '}
        <Icons.arrowDropDownIcon
          className={clsx(
            classes.arrowIcon,
            popupStateSolutions.isOpen === true && classes.linkActive,
          )}
        />
      </Link>

      {/*<Link className={classes.linkButton}>{t.labsAndInstitutions}</Link>*/}

      {/*<Link className={classes.linkButton}>{t.rewards}</Link>*/}

      <Link href="/about-us" target="_blank" className={classes.linkButton} underline="hover">
        {t.aboutUs}
      </Link>

      <Link
        className={clsx(
          classes.linkButton,
          popupStateSupport.isOpen === true && classes.linkActive,
        )}
        {...bindHover(popupStateSupport)}
        underline="hover"
      >
        {t.support}{' '}
        <Icons.arrowDropDownIcon
          className={clsx(
            classes.arrowIcon,
            popupStateSupport.isOpen === true && classes.linkActive,
          )}
        />
      </Link>

      {dropDownSupport()}
    </Box>
  );
}

NavBarOneResearcher.propTypes = Props;
NavBarOneResearcher.defaultProps = DefaultProps;
