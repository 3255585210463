import { makeStyles } from '@mui/styles';
import {
  COLOR_GRAY_30,
  COLOR_PINK,
  COLOR_PURPLE,
  COLOR_PURPLE_HOVER,
  COLOR_WHITE,
} from 'styles/variables/colors';

export const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1.5, 3),
    textTransform: 'none',
    fontWeight: theme.typography.fontWeightBold,
    borderRadius: 40,
  },
  containedPrimary: {
    color: theme.palette.common.white,
    backgroundColor: COLOR_PURPLE,

    '&:hover': {
      backgroundColor: COLOR_PURPLE_HOVER,
    },
  },
  textPrimary: {
    color: COLOR_PURPLE,
  },
  buttonWhite: {
    backgroundColor: theme.palette.common.white,
    color: '#2B2A2F',

    '&:hover': {
      backgroundColor: theme.palette.common.white,
      color: COLOR_PURPLE,
    },
  },
  buttonSecondary: {
    color: theme.palette.common.white,
    backgroundColor: '#D6246E',

    '&:hover': {
      backgroundColor: '#D6246E',
      filter: 'brightness(0.9)',
    },
  },
  buttonWhiteTextPurple: {
    backgroundColor: theme.palette.common.white,
    color: COLOR_PURPLE,
    border: '2px solid #FFFFFF',

    '&:hover': {
      backgroundColor: '#EEEEEE',
      color: COLOR_PURPLE_HOVER,
    },
  },
  transparentButtonBorderWhite: {
    color: theme.palette.common.white,
    border: '2px solid #FFFFFF',
  },
  ctaPink: {
    color: '#D6246E',
    backgroundColor: 'transparent',
    border: '2px solid #D6246E',
    padding: theme.spacing(1.3, 3),

    '&:hover': {
      backgroundColor: '#D6246E',
      color: COLOR_WHITE,
    },
  },
  ctaPrimary: {
    color: COLOR_WHITE,
    backgroundColor: '#7833FF',

    '&:hover': {
      backgroundColor: COLOR_PURPLE_HOVER,
    },
  },
  ctaPrimaryOutline: {
    color: COLOR_PURPLE,
    border: '2px solid #7833FF',
    backgroundColor: 'transparent',
    padding: theme.spacing(1.3, 3),

    '&:hover': {
      backgroundColor: COLOR_PURPLE_HOVER,
      color: COLOR_WHITE,
    },
  },
  buttonSecondaryOpenModal: {
    color: theme.palette.common.white,
    backgroundColor: '#D6246E',

    '&:hover': {
      backgroundColor: '#D6246E',
      filter: 'brightness(0.9)',
    },
  },
  ctaPinkOutline: {
    color: COLOR_PINK,
    border: `1px solid ${COLOR_PINK}`,
  },
  outlinedNew: {
    textTransform: 'none',
    borderColor: COLOR_GRAY_30,
    borderRadius: 8,
    minHeight: 50,
    padding: theme.spacing(0),
    fontWeight: 600,
    fontSize: 12,
    '.MuiButton-startIcon': {
      marginRight: 18,
    },
  },
}));
