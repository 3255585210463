import React from 'react';
import PropTypes from 'prop-types';
import { IconImg, IconContainer, UserImg } from './styles';
import { UserTypeEnum } from '../../enums';
import { useAuthUser } from 'features/auth/AuthUserContext';

const propTypes = {
  src: PropTypes.string,
  size: PropTypes.oneOf(['sm', 'md', 'lg']).isRequired,
  margin: PropTypes.string,
};

export default function ProfileAvatar({ src, size, margin }) {
  const { user } = useAuthUser();

  function getDefaultAvatarSrc() {
    switch (user.tp_user) {
      case UserTypeEnum.RESEARCHER:
        return '/images/principalInvestigator.svg';
      case UserTypeEnum.STUDENT:
        return '/images/gradStudent.svg';
      default:
        return '/images/undergradStudent.svg';
    }
  }

  if (src) {
    return (
      <UserImg
        margin={margin || ''}
        size={size}
        src={`https://s3-us-west-2.amazonaws.com/users-dev.mindthegraph.com/pictures/small/${src}`}
        alt="User avatar"
      />
    );
  }

  return (
    <IconContainer margin={margin || ''} size={size}>
      <IconImg src={getDefaultAvatarSrc()} alt="User avatar" />
    </IconContainer>
  );
}

ProfileAvatar.propTypes = propTypes;
