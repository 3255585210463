export default {
  lang: 'en',
  allSolutions: 'All solutions',
  labsAndInstitutions: 'Labs & Institutions',
  rewards: 'Rewards',
  aboutUs: 'About us',
  support: 'Support',

  editageName: 'Editage',
  editageDescription: 'One platform for all researcher needs',
  editageLink: 'https://www.editage.com?source=mtg_row_globalnav',

  paperpalName: 'Paperpal',
  paperpalDescription: 'AI-powered academic writing assistant',
  paperpalLink: 'https://paperpal.com?source=mtg_row_globalnav',

  rDiscoveryName: 'R Discovery',
  rDiscoveryDescription: 'Your #1 AI companion for literature search',
  rDiscoveryLink: 'https://discovery.researcher.life?source=mtg_row_globalnav',

  mindTheGraphName: 'Mind the Graph',
  mindTheGraphDescription: 'AI tool for graphics, illustrations, and artwork',
  mindTheGraphLink: '/',

  unlockUnlimitedText: (
    <>
      Unlock unlimited use of all AI tools with <br /> the <span>Editage All Access</span>{' '}
      membership.
    </>
  ),
  unlockUnlimitedButtonText: 'Explore Editage All Access',
  unlimitedLink: 'https://www.editage.com/all-access-pack?source=mtg_row_globalnav',
};
